import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import {
  blackHeart,
  cart,
  delIcon,
  laptopCart,
  logo,
  profile,
  search,
} from "../../assets/imagesPath";
import { Link, useNavigate } from "react-router-dom";
import SigninModal from "../allModals/SignupModal";
// import Button from '../button/Button';
import PATHS from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../allModals/loginModal";
import { userLogout } from "../../redux/AuthSlice";
import {
  useViewCartOrdersQuery,
} from "../../services/Api";
// import { setSearch } from "../../redux/ProductSlice";
import Button from "../button/Button";
import { setSearch } from "../../redux/ProductSlice";

const MidNavbar = ({ test }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginModal2, setShowLoginModal2] = useState(false);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((data) => data?.products?.productDetail);
  const auth = useSelector((data) => data.auth);
  const [searchTxt, setSearchTxt] = useState("");
  const { isLoggedIn, userId: ID } = auth || {};
  const { data: viewCartOrders } = useViewCartOrdersQuery({
    params: { user_id: ID, order_status: 0 },
  });

  // const [showCartDropdown, setShowCartDropdown] = useState(false);
  const cartRef = useRef(null);

  const signupModalHandler = () => {
    setShowLoginModal((prev) => !prev);
  };

  const loginModalHandler = () => {
    setShowLoginModal2((prev) => !prev);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(setSearch(searchTxt));
    }
  };

  useEffect(() => {
    if (!searchTxt) {
      dispatch(setSearch(""));
    }
  }, [searchTxt]);

  return (
    <>
      <nav className="d-block d-md-flex navbar navbar-expand-lg justify-content-between paddingTopnav">
        <div className="text-center mb-3 mb-md-0">
          <img
            src={logo}
            className="img-fluid brand-logo cursor"
            onClick={() => navigator(PATHS.home)}
          />
        </div>

        <div className="position-relative w-100 w-md-50 ms-md-5 ms-0 mb-3 mb-md-0 me-md-4">
          <input
            type="text"
            className="mainSearchbar"
            placeholder="Search essentials, groceries and more..."
            onFocus={() => navigator(PATHS.shop)}
            onChange={(e) => setSearchTxt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <img src={search} className="searchIcon" />
        </div>

        <div>
          <Button
            style={{ height: 43 }}
            label="Search"
            className="greenBtn w-100"
            onClick={() => dispatch(setSearch(searchTxt))}
          />
        </div>

        <div className="align-items-center justify-content-end ms-3">
          <div className="d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center midnavText">
              {!isLoggedIn && (
                <>
                  <li className="nav-item">
                    <Link
                      style={{ fontWeight: "bold" }}
                      className="nav-link"
                      data-toggle="tab"
                      onClick={loginModalHandler}
                    >
                      Login
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      style={{ fontWeight: "bold" }}
                      onClick={signupModalHandler}
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Signup
                    </Link>
                  </li>
                </>
              )}

              <li
                className="nav-item mt-1"
                onClick={() => navigator(PATHS.cart)}
              >
                <div className="position-relative" ref={cartRef}>
                  <div className="cursor">
                    <img src={cart} alt="" />
                    {viewCartOrders?.data.order_products?.length > 0 ? (
                      <span className="cartItemsCount">
                        {viewCartOrders?.data?.order_products?.length}
                      </span>
                    ) : null}
                  </div>
                </div>
              </li>

              {/* <li className='nav-item'>
                <Link
                  // to={PATHS.mechanichome}
                  className='nav-link'
                  data-toggle='tab'
                >
                  <img src={blackHeart} alt="" />
                </Link>
              </li> */}

              {/* <li className='nav-item mt-1'>
                <div className='position-relative' ref={cartRef}>
                  <div className='cursor' onClick={cartDropdownHandler}>
                    <img src={cart} alt="" />
                    {!showCartDropdown &&
                      <span className='cartItemsCount'>0</span>
                    }
                  </div>

                  {showCartDropdown &&
                    <ul className='ps-4 pe-4 cartDropdown customScroll'>

                      <li>
                        <p className='cartDropHeading pt-4 pb-4'>Shopping Cart</p>
                      </li>

                      <li className='cardProdDiv mt-4'>
                        <div className="cartDropImgDiv">
                          <img src={laptopCart} className='img-fluid' />
                        </div>

                        <div className='pe-3'>
                          <div className='d-flex justify-content-between align-items-start'>
                            <p className="cartDropDesc">dELL E6540 i5-4th | 8GB | 500GB HDD | 15.6” Screen</p>
                            <img src={delIcon} alt="" />
                          </div>

                          <div className='d-flex justify-content-between align-items-end'>
                            <div>
                              <p className="cartDropPrice">Rs: 10,000</p>
                              <p className="cartDropAdv">Advance Payment</p>
                            </div>

                            <div className='cartDropCounter'>
                              <span className='cursor'>-</span>
                              <span>2</span>
                              <span className='cursor'>+</span>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className='cardProdDiv mt-4'>
                        <div className="cartDropImgDiv">
                          <img src={laptopCart} className='img-fluid' />
                        </div>

                        <div className='pe-3'>
                          <div className='d-flex justify-content-between align-items-start'>
                            <p className="cartDropDesc">dELL E6540 i5-4th | 8GB | 500GB HDD | 15.6” Screen</p>
                            <img src={delIcon} alt="" />
                          </div>

                          <div className='d-flex justify-content-between align-items-end'>
                            <div>
                              <p className="cartDropPrice">Rs: 10,000</p>
                              <p className="cartDropAdv">Advance Payment</p>
                            </div>

                            <div className='cartDropCounter'>
                              <span className='cursor'>-</span>
                              <span>2</span>
                              <span className='cursor'>+</span>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className='cardProdDiv mt-4'>
                        <div className="cartDropImgDiv">
                          <img src={laptopCart} className='img-fluid' />
                        </div>

                        <div className='pe-3'>
                          <div className='d-flex justify-content-between align-items-start'>
                            <p className="cartDropDesc">dELL E6540 i5-4th | 8GB | 500GB HDD | 15.6” Screen</p>
                            <img src={delIcon} alt="" />
                          </div>

                          <div className='d-flex justify-content-between align-items-end'>
                            <div>
                              <p className="cartDropPrice">Rs: 10,000</p>
                              <p className="cartDropAdv">Advance Payment</p>
                            </div>

                            <div className='cartDropCounter'>
                              <span className='cursor'>-</span>
                              <span>2</span>
                              <span className='cursor'>+</span>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className='mt-5'>
                        <div className='d-flex justify-content-between align-items-center ps-4 pe-4 pb-3'>
                          <p className="subTotal">Subtotal</p>
                          <p className="cartSubtotal">Rs. 250,000.00</p>
                        </div>
                      </li>

                      <li className='cartDropFooter'>
                        <div className='cartFooterMargin'>
                          <Link to="/cart">
                            <Button label="View Cart" className="blackBtn me-5" onClick={() => setShowCartDropdown(false)} />
                          </Link>
                          <Button label="Checkout" className="blackBtn" />
                        </div>
                      </li>

                    </ul>
                  }
                </div>
              </li> */}
              {isLoggedIn && (
                <li>
                  <div className="dropdown profileDropdown mx-auto">
                    <a
                      className="d-flex align-items-center decorNone flex-column flex-lg-row gap-1  text-capitalize"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        height={25}
                        width={25}
                        className="me-2"
                      />
                    </a>

                    <ul
                      className="dropdown-menu userDropdownMenu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <Link to={PATHS.profile} className="decorNone">
                        <li>
                          <p className="dropdown-item">Profile</p>
                        </li>
                      </Link>
                      <hr className="m-1" />
                      <li
                        className="cursor"
                        onClick={() => dispatch(userLogout())}
                      >
                        <p className="dropdown-item">Logout</p>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {showLoginModal && (
        <SigninModal handleCloseSignupModal={signupModalHandler} />
      )}
      {showLoginModal2 && (
        <LoginModal handleCloseSignupModal={loginModalHandler} />
      )}
    </>
  );
};

export default MidNavbar;
