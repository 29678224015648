import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "authSlice",
  version: 1,
  storage,
  blacklist: [],
};

const initialState = {
  isLoggedIn: false,
  userDetail: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    register: (state) => {
      state.isLoggedIn = false;
      state.userDetail = null;
      state.token = null;
      state.userId = null;
      state.fcmtoken = null;
    },

    loggedIn: (state, data) => {
      state.isLoggedIn = true;
      state.userDetail = data.payload.user;
      state.token = data.payload.token;
      state.userId = data.payload.user.id;
    },

    userLogout: (state) => {
      state.isLoggedIn = false;
      state.userDetail = null;
      state.token = null;
      state.userId = null;
    },

    updateUserProfile: (state, data) => {
      state.userDetail.address = data.payload;
      // state.userDetail.address = { ...state.userDetail.address, ...data.payload };
    },
  },
});

export const {
  loggedIn,
  userLogout,
  rememberMeSlice,
  updateUserProfile,
  register,
} = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
