import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "productSlice",
  version: 1,
  storage,
  blacklist: [],
};

const initialState = {
  productDetail: [],
  search: "",
};

export const authSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productDetails: (state, data) => {
      if (data?.payload !== null) {
        const newData = data;
        const isDuplicate = state.productDetail.some(
          (item) => item?.payload?.id == newData?.payload?.id
        );

        if (!isDuplicate) {
          state.productDetail = [newData, ...state.productDetail];
        }
      } else {
        state.productDetail = [];
      }
    },

    deleteProduct: (state, data) => {
      if (state.productDetail) {
        let filterArray = state.productDetail.filter(
          (item) => item?.payload?.id != data.payload
        );
        state.productDetail = filterArray;
      }
    },
    setSearch: (state, data) => {
      state.search = data.payload;
    },
  },
});

export const { productDetails, deleteProduct, setSearch } = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
