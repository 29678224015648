import React from "react";
import "./Button.css";
import { loaderAsset } from "../../assets/imagesPath";
// import { colorfullLoader, gifLoaderAsset } from "../../assets/index";

const Button = ({
  className,
  icon,
  imgStyle,
  alt,
  onClick,
  label,
  status,
  disabled = false,
  type = "button",
  loading = false,
  loadingDelete = false,
  id,
  style,
}) => {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      className={className}
      disabled={loading || disabled || loadingDelete}
      id={id}
    >
      {!loading && label}
      {loading ? (
        <img src={loaderAsset} height="30" width="30" />
      ) : (
        icon && <img className="ms-3" src={icon} alt={alt} width="22" />
      )}
    </button>
  );
};

export default Button;
