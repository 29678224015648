import Router from './routes/routes';
import Navbar from './components/navbar/Navbar';
import Footer from './components/Footer/Footer';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-phone-input-2/lib/style.css";
import { whatsapp } from './assets/imagesPath';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

function App() {

  const openWhatsAppChat = () => {
    window.open('https://wa.me/+923004155667', '_blank');
  };

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Router />
      <Footer />
      <img src={whatsapp} className='whatsappImg' onClick={openWhatsAppChat} />
    </>
  );
}

export default App;